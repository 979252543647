// @flow
import React from 'react';
import Layout from '../../components/layout';
import Link from '../../components/link';
import Image from '../../components/image';
import type { FrontMatter, ImageType } from '../../utils/types';
import { withFrontMatter } from '../../components';
import { graphql } from 'gatsby';
import Carousel from '../../components/carousel';

type Props = {
  frontMatter: FrontMatter,
  data: {
    atc: ImageType,
    bluugnome_sqwurel_v3: ImageType,
    climbing_technology_groove: ImageType,
    cmi_el_diablo: ImageType,
    edelrid_hannibal: ImageType,
    edelrid_mago_8: ImageType,
    figure_8: ImageType,
    imlay_critr2: ImageType,
    kong_oka: ImageType,
    mammut_nano_8: ImageType,
    none: ImageType,
    on_rope_canyoneering_hoodoo_2: ImageType,
    petzl_pirana: ImageType,
    phoenix_crt: ImageType,
    rescue_8: ImageType,
    resonator_3000: ImageType,
    rock_exotica_minieight: ImageType,
    rock_exotica_totem: ImageType,
    rq3_rescue_q_eight: ImageType,
    shirt: ImageType,
    sterling_ats: ImageType,
    vt_prusik: ImageType,
  },
};

class Blog_2020_02_23_The_2020_Utah_Canyoneers_Shirt extends React.Component<Props> {
  render() {
    const { data, frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter}>
        <Image
          image={data.shirt}
          caption={
            <>
              The{' '}
              <Link to="https://www.facebook.com/groups/1589401311365080/permalink/2244510555854149/">
                2020 Utah Canyoneers shirt
              </Link>{' '}
              by Jamie Hill. Unfortunately, the shirt is no longer available for
              purchase.
            </>
          }
        />
        <hr />
        <h2>The Rappel Devices</h2>
        <Carousel
          interval={5000}
          images={[
            data.mammut_nano_8,
            data.climbing_technology_groove,
            data.kong_oka,
            data.atc,
            data.rescue_8,
            data.on_rope_canyoneering_hoodoo_2,
            data.edelrid_mago_8,
            data.rock_exotica_minieight,
            data.cmi_el_diablo,
            data.bluugnome_sqwurel_v3,
            data.edelrid_hannibal,
            data.resonator_3000,
            data.figure_8,
            data.rq3_rescue_q_eight,
            data.rock_exotica_totem,
            data.phoenix_crt,
            data.petzl_pirana,
            data.sterling_ats,
            data.imlay_critr2,
            data.vt_prusik,
          ]}
          captions={[
            <Link to="https://www.mammut.com/us/en/p/2210-01810-2016/nano-8/">
              Mammut Nano 8
            </Link>,
            <Link to="https://www.climbingtechnology.com/en/outdoor-en/belay-devices-and-descenders/platelets/groove">
              Climbing Technology Groove
            </Link>,
            <Link to="https://www.kong.it/en/2-products/items/f19-descenders/p454-oka">
              Kong Oka
            </Link>,
            <>
              <Link to="https://www.blackdiamondequipment.com/en_US/climbing-belay-rappel/atc-xp-BD620075_cfg.html">
                Black Diamond ATC-XP
              </Link>{' '}
              - probably the most common style of rappel device in canyoneering
            </>,
            <>
              Rescue 8 - maybe the{' '}
              <Link to="https://www.climbingtechnology.com/en/professional-en/descenders/otto-rescue">
                Climbing Technology Otto Rescue
              </Link>{' '}
              or the{' '}
              <Link to="https://madrock.com/products/large-rescue-8">
                Mad Rock Rescue 8
              </Link>
            </>,
            <Link to="https://www.onropecanyoneering.com/canyoneering-gear/hoodoo-sl">
              On Rope Canyoneering Hoodoo-SL
            </Link>,
            <Link to="https://www.edelrid.de/en/sports/descender/mago-8.html">
              Edelrid Mago 8
            </Link>,
            <Link to="https://www.rockexotica.com/minieight">
              Rock Exotica MiniEight
            </Link>,
            <Link to="https://cmigearusa.com/products/el-diablo">
              CMI El Diablo
            </Link>,
            <>
              <Link to="https://www.bg-gear.com/store/index.php?main_page=product_info&cPath=1&products_id=209">
                BluuGnome SQWUREL V3
              </Link>{' '}
              - SQWUREL doesn't stand for anything
            </>,
            <Link to="https://www.edelrid.de/en/sports/descender/hannibal.html">
              Edelrid Hannibal
            </Link>,
            <Link to="http://ropewiki.com/Resonator_3000">Resonator 3000</Link>,
            <>
              Generic Figure 8 - not sure which one because there are hundreds
              of manufacturers
            </>,
            <Link to="https://rescuesource.com/shop/hardware/rigging-plates/rescue-q-eight/">
              RQ3 Rescue Q-Eight
            </Link>,
            <Link to="https://www.rockexotica.com/product/descenders/totem">
              Rock Exotica Totem
            </Link>,
            <>
              <Link to="https://canyonsandcrags.com/product/totem-crt/">
                Phoenix CRT
              </Link>{' '}
              or maybe the{' '}
              <Link to="https://canyonsandcrags.com/product/phoenix-lrt/">
                LRT
              </Link>
              . Used to be called the Totem CRT. CRT stands for{' '}
              <Link to="https://canyonsandcrags.com/rigging-rescue">
                Canyon Rescue Technician
              </Link>
            </>,
            <Link to="https://www.petzl.com/US/en/Sport/Belay-devices-and-descenders/PIRANA">
              Petzl Pirana
            </Link>,
            <>
              <Link to="https://sterlingrope.com/store/work/hardware/descent-control-rigging/ats">
                Sterling ATS
              </Link>{' '}
              - ATS stands for Alpine Training Systems
            </>,
            <>
              <Link to="https://www.canyoneeringusa.com/store/canyon-werks-critr2-rappel-device">
                Imlay CRITR2
              </Link>{' '}
              - I think the silhouette is actually the original CRITR. CRITR
              stands for Canyon Rappel Invented by Todd Rentchler
            </>,
            <>
              <Link to="https://www.bluewaterropes.com/product/7mm-vt-prusik/">
                VT Prusik
              </Link>{' '}
              - wait this is a rappel device? Not really, but you <em>can</em>{' '}
              use it to descend a rope. Kind of. It's dangerous. Also I don't
              think this is technically a VT Prusik silhouette because the cord
              part looks too long for the size of the eye holes. But it doesn't
              matter because the shirt is amazing. Seriously buy one when they
              become available.
            </>,
          ]}
        />
        <h2>Missing rappel devices</h2>
        <p>
          There are way too many to name. Like, there are hundreds of rappel
          devices. A few examples are the Kong Robot and Hydrobot, Conterra
          Scarab, all the old versions of rappel devices that are on there, and
          that one that has one big circle that always pops up on facebook.
        </p>
        <p>
          If you want to see tons of rappel devices go to{' '}
          <Link to="http://www.storrick.digitalspacemail8.net/VerticalDevicesPage/RappelDevices.shtml">
            Dr. Gary D. Storrick's Vertical Devices Page
          </Link>
        </p>
      </Layout>
    );
  }
}

export default withFrontMatter('2020-02-23-the-2020-utah-canyoneers-shirt')(
  Blog_2020_02_23_The_2020_Utah_Canyoneers_Shirt
);

export const query = graphql`
  query {
    atc: file(
      relativePath: {
        eq: "images/blog/2020-02-23-the-2020-utah-canyoneers-shirt/atc.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bluugnome_sqwurel_v3: file(
      relativePath: {
        eq: "images/blog/2020-02-23-the-2020-utah-canyoneers-shirt/bluugnome-sqwurel-v3.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    climbing_technology_groove: file(
      relativePath: {
        eq: "images/blog/2020-02-23-the-2020-utah-canyoneers-shirt/climbing-technology-groove.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    cmi_el_diablo: file(
      relativePath: {
        eq: "images/blog/2020-02-23-the-2020-utah-canyoneers-shirt/cmi-el-diablo.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    edelrid_hannibal: file(
      relativePath: {
        eq: "images/blog/2020-02-23-the-2020-utah-canyoneers-shirt/edelrid-hannibal.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    edelrid_mago_8: file(
      relativePath: {
        eq: "images/blog/2020-02-23-the-2020-utah-canyoneers-shirt/edelrid-mago-8.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    figure_8: file(
      relativePath: {
        eq: "images/blog/2020-02-23-the-2020-utah-canyoneers-shirt/figure-8.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    imlay_critr2: file(
      relativePath: {
        eq: "images/blog/2020-02-23-the-2020-utah-canyoneers-shirt/imlay-critr2.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    kong_oka: file(
      relativePath: {
        eq: "images/blog/2020-02-23-the-2020-utah-canyoneers-shirt/kong-oka.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    mammut_nano_8: file(
      relativePath: {
        eq: "images/blog/2020-02-23-the-2020-utah-canyoneers-shirt/mammut-nano-8.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    none: file(
      relativePath: {
        eq: "images/blog/2020-02-23-the-2020-utah-canyoneers-shirt/none.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    on_rope_canyoneering_hoodoo_2: file(
      relativePath: {
        eq: "images/blog/2020-02-23-the-2020-utah-canyoneers-shirt/on-rope-canyoneering-hoodoo-2.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    petzl_pirana: file(
      relativePath: {
        eq: "images/blog/2020-02-23-the-2020-utah-canyoneers-shirt/petzl-pirana.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    phoenix_crt: file(
      relativePath: {
        eq: "images/blog/2020-02-23-the-2020-utah-canyoneers-shirt/phoenix-crt.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    rescue_8: file(
      relativePath: {
        eq: "images/blog/2020-02-23-the-2020-utah-canyoneers-shirt/rescue-8.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    resonator_3000: file(
      relativePath: {
        eq: "images/blog/2020-02-23-the-2020-utah-canyoneers-shirt/resonator-3000.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    rock_exotica_minieight: file(
      relativePath: {
        eq: "images/blog/2020-02-23-the-2020-utah-canyoneers-shirt/rock-exotica-minieight.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    rock_exotica_totem: file(
      relativePath: {
        eq: "images/blog/2020-02-23-the-2020-utah-canyoneers-shirt/rock-exotica-totem.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    rq3_rescue_q_eight: file(
      relativePath: {
        eq: "images/blog/2020-02-23-the-2020-utah-canyoneers-shirt/rq3-rescue-q-eight.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    shirt: file(
      relativePath: {
        eq: "images/blog/2020-02-23-the-2020-utah-canyoneers-shirt/shirt.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sterling_ats: file(
      relativePath: {
        eq: "images/blog/2020-02-23-the-2020-utah-canyoneers-shirt/sterling-ats.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    vt_prusik: file(
      relativePath: {
        eq: "images/blog/2020-02-23-the-2020-utah-canyoneers-shirt/vt-prusik.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;

// atc.png
// bluugnome-sqwurel-v3.png
// climbing-technology-groove.png
// cmi-el-diablo.png
// edelrid-hannibal.png
// edelrid-mago-8.png
// figure-8.png
// imlay-critr2.png
// kong-oka.png
// mammut-nano-8.png
// none.png
// on-rope-canyoneering-hoodoo-2.png
// petzl-pirana.png
// phoenix-crt.png
// rescue-8.png
// resonator-3000.png
// rock-exotica-minieight.png
// rock-exotica-totem.png
// rq3-rescue-q-eight.png
// shirt.jpg
// sterling-ats.png
// vt-prusik.png
